import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

export default class DeleteCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {

    } = this.state

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a className=" btn btn-icon btn-light-primary btn-hover-primary btn-sm min-w-auto me-1 delete"
           data-bs-toggle="" data-bs-target=""
           onClick={e => this.props.onDeleteClicked()}
        >
          <span className="material-icons-outlined fs-2">clear</span>
        </a>
      </Tooltip>
    )
  }
}

DeleteCell.defaultProps = {
  tooltip: 'Archive'
}