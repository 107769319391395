import React from "react"

import Lists from "./components/linkie/Lists";

export default class FixedCollections extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="pages manage-lists">
          <div className="g-5 gx-xxl-8">
            <div className="text-center">
              <Lists
                type={"system_default"}
              />
            </div>
          </div>
        </div>
      </>
    )

  }

}