import React from 'react'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Alert from 'react-bootstrap/Alert'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Email from "../../../utils/Email";

export default class Partner extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      error: null,
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _getState(props){
    return {
      ...props,
      show: props.show,
      partner: props.partner ? props.partner : {customer:{user:{}}},
    }
  }

  _handleChange(e) {
    let partner = this.state.partner
    partner.customer.user[e.target.name] = e.target.value
    this.setState({ partner, error: null })
  }

  _isFormValid() {
    let { first_name, last_name, email, password } = this.state.partner.customer.user

    let error = null

    if(first_name == null || first_name.length === 0){
      error = "Please enter the partner first name"
    }else if(last_name == null || last_name.length === 0){
      error = "Please enter the partner last name"
    }else if (!Email.isValid(email)) {
      error = 'Please enter a valid partner email address'
    }else if(password == null || password.length === 0){
      error = 'Please enter a valid partner password'
    }else if(!this.state.partner.commission_percentage){
      error = 'Please enter a valid commission'
    }

    if(error){
      General.showNotification(error, false)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      partner
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    if(partner.id){
      this._updatePartner(partner)
    }else{
      this._addPartner(partner)
    }
  }

  _addPartner(partner){
    Backend.addPartner(partner).then(partner => {
      this.setState({ loading: false })
      this.props.onSaved(partner)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  _updatePartner(partner){
    Backend.updatePartner(partner).then(partner => {
      this.setState({ loading: false })
      this.props.onSaved(partner)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  render() {
    let {
      show,
      loading,
      partner,
      error
    } = this.state

    let title = partner.id ? "Update Partner" : "Add New Partner"
    let buttonTitle = partner.id ? "Update" : "Save"

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 my-7'
        >

          { error &&
            <Alert variant={"danger"}>
              { error }
            </Alert>
          }

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">First Name</span>
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-control-solid"
              value={ partner.customer.user.first_name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Last Name</span>
            </label>
            <input
              type="text"
              name="last_name"
              className="form-control form-control-solid"
              value={ partner.customer.user.last_name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Email</span>
            </label>
            <input
              type="text"
              name="email"
              className="form-control form-control-solid"
              value={ partner.customer.user.email }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Password</span>
            </label>
            <input
              type="password"
              name="password"
              className="form-control form-control-solid"
              value={ partner.customer.user.password }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Commission %</span>
            </label>
            <input
              type="text"
              name="name"
              className="form-control form-control-solid"
              value={ partner.commission_percentage * 100 || '' }
              onChange={e => {
                partner.commission_percentage = (e.target.value / 100).toFixed(2)
                this.setState({partner})
              }}
            />
          </div>

          <div className='text-center pt-15'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
              >
                Discard
              </button>

              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={() => this._handleSave()}
              >
                {buttonTitle}
              </button>
            </div>
          </div>


        </Modal.Body>
        
      </Modal>
    )
  }
}
