import React from 'react'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Alert from 'react-bootstrap/Alert'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Event from "../../../utils/Event";
import AsyncSelect from "../common/AsyncSelect";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class ListLinkie extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      error: null,
      show: props.show,
      type: props.type,
      lists: [],
      linkie: props.linkie ? props.linkie : {list_ids: []},
    }
  }

  componentDidMount() {
    let {
      type
    } = this.state

    this._parseLinkie()
    if(type !== "system_popular"){
      Backend.getLists(type).then(lists => {
        this.setState({lists})
      }).catch(e => General.showNotification(e.message, false))
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._parseLinkie())
  }

  _parseLinkie(){
    let {
      linkie
    } = this.state

    if(linkie.id){
      Backend.getLinkie(linkie.id).then(linkie => {
        linkie.list_ids = linkie.lists.map(list => list.id)
        this.setState({linkie})
      }).catch(e => General.showNotification(e.message, false))
    }
  }

  _handleChange(e) {
    let linkie = this.state.linkie
    linkie[e.target.name] = e.target.value
    this.setState({ linkie, error: null })
  }

  _isFormValid(){
    let {
      name,
      url,
      list_ids
    } = this.state.linkie

    let error = null
    if(name == null || name.length === 0){
      error = "Please enter a valid title"
    }else if(url == null || url.length === 0){
      error = "Please enter a valid url"
    }else if(list_ids.length < 1 && this.state.type !== "system_popular"){
      error = "Please select a Collection"
    }

    if(error){
      General.showNotification(error, false)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      linkie
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    if(linkie.id){
      this._updateLinkie(linkie)
    }else{
      this._addLinkie(linkie)
    }
  }

  _addLinkie(linkie){
    Backend.addLinkie(linkie).then(linkie => {
      this.setState({ loading: false })
      General.showNotification("Website Saved")
      this.props.onSaved(linkie)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  _updateLinkie(linkie){
    Backend.updateLinkie(linkie).then(linkie => {
      this.setState({ loading: false })
      this.props.onSaved(linkie)
      General.showNotification("Website Updated")
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  render() {
    let {
      show,
      type,
      loading,
      linkie,
      lists,
      error
    } = this.state

    let title = linkie.id ? "Update Website" : "Create Website"
    let buttonTitle = linkie.id ? "Update" : "Save"

    return (
      <Modal
        show={show}
        className="add-new-linkie"
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 my-7'
        >

          { error &&
            <Alert variant={"danger"}>
              { error }
            </Alert>
          }

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Website Name</span>
              <OverlayTrigger
                overlay={
                  <Tooltip >
                    Update the name of Website
                  </Tooltip>
                }
              >
                <i className="fas fa-exclamation-circle ms-2 fs-7" aria-label="Update the name of Website"></i>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              name="name"
              className="form-control form-control-solid"
              value={ linkie.name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="required fs-6 fw-bold form-label mb-2">Link URL</label>
            <input
              type="text"
              name="url"
              className="form-control form-control-solid"
              value={ linkie.url }
              onChange={e => this._handleChange(e)}
            />
          </div>

          {
            type !== "system_popular" &&
            <>
              <hr className="mb-7 dashed"/>

              <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold form-label mb-2">
                  Collections<br/>
                  <small>Please choose which collections you want to add the Website to</small>
                </label>
                <div className="lists">
                  <div className="row">
                    <div className="col">
                      {
                        lists.map(list => (
                          <>
                            <input
                              type="checkbox"
                              className="btn-check"
                              id={`list-${list.id}`}
                              autoComplete="off"
                              checked={linkie.list_ids?.includes(list.id)}
                              onChange={e => {
                                let index = linkie.list_ids.indexOf(list.id)
                                if(index !== -1){
                                  linkie.list_ids.splice(index, 1)
                                }else{
                                  linkie.list_ids.push(list.id)
                                }
                                this.setState({linkie})
                              }}
                            />
                            <label className="btn btn-light-primary btn-sm" htmlFor={`list-${list.id}`}>{list.name}</label>
                          </>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

          <div className='text-center pt-15'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.props.onHide()}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={() => this._handleSave()}
              >
                {buttonTitle}
              </button>
            </div>
          </div>

        </Modal.Body>

        
      </Modal>
    )
  }
}
