import React from "react"

import Websites from "./components/linkie/Websites";

export default class PopularWebsites extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    let {

    } = this.state

    return (
      <>
        <Websites
          type="system_popular"
        />
      </>
    )

  }

}
