import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from './components/structure/LeftMenu'
import Footer from './components/structure/Footer'
import Header from './components/structure/Header'

import AsyncStorage from '../utils/AsyncStorage'

import Dashboard from "./Dashboard";
import Customers from "./Customers";
import OnboardingCollections from "./OnboardingCollections";
import OnboardingWebsites from "./OnboardingWebsites";
import PopularWebsites from "./PopularWebsites";
import Partners from "./Partners";
import FixedWebsites from "./FixedWebsites";
import FixedCollections from "./FixedCollections";

import Event from "../utils/Event";

const LOGO = require('../assets/media/logo.png')

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refresh: false
    }
  }

  _renderRoutes(){
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/users" component={Customers} />
        <Route exact path="/referral-partners" component={Partners} />
        <Route exact path="/onboarding-collections" component={OnboardingCollections} />
        <Route exact path="/onboarding-websites" component={OnboardingWebsites} />
        <Route exact path="/fixed-collections" component={FixedCollections} />
        <Route exact path="/fixed-websites" component={FixedWebsites} />
        <Route exact path="/trending-websites" component={PopularWebsites} />
        {/*<Route exact path="/manage-lists" component={Lists} />*/}
      </Switch>
    )
  }

  render() {
    let {
      linkie,
      selectedList,
      showListModal,
      selectedLinkieList,
      showLinkieModal,
    } = this.state

    return (
      <>
        <div className="page-loader flex-column">
          <img alt="Logo" className="mh-75px" src={LOGO}/>
          <div className="d-flex align-items-center mt-5">
            <span className="spinner-border text-primary" role="status"></span>
            <span className="text-muted fs-6 fw-bold ms-5">Loading...</span>
          </div>
        </div>

        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div id="notification-container" className="text-center">
              <div
                id="notification-content"
              ></div>
            </div>
            <LeftMenu/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>
              <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-fluid">
                    { this._renderRoutes() }
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </>
    )
  }
}
