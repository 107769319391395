import React from 'react'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Alert from 'react-bootstrap/Alert'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";

export default class Category extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      error: null,
      selectable: window.location.pathname === '/onboarding-collections',
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _getState(props){
    let selectable = window.location.pathname === '/onboarding-collections'
    return {
      ...props,
      show: props.show,
      category: props.category ? props.category : {selectable: selectable},
    }
  }

  _handleChange(e) {
    let category = this.state.category
    category[e.target.name] = e.target.value
    this.setState({ category, error: null })
  }

  _isFormValid(){
    let {
      name,
    } = this.state.category

    let error = null
    if(name == null || name.length === 0){
      error = "Please enter a valid name"
    }

    if(error){
      General.showNotification(error, false)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      category
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    if(category.id){
      this._updateCategory(category)
    }else{
      this._addCategory(category)
    }
  }

  _addCategory(category){
    Backend.addCategory(category).then(category => {
      this.setState({ loading: false })
      this.props.onSaved(category)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  _updateCategory(category){
    Backend.updateCategory(category).then(category => {
      this.setState({ loading: false })
      this.props.onSaved(category)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  render() {
    let {
      show,
      loading,
      category,
      error
    } = this.state

    let title = category.id ? "Update Category" : "Add New Category"
    let buttonTitle = category.id ? "Update" : "Save"

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 my-7'
        >

          { error &&
            <Alert variant={"danger"}>
              { error }
            </Alert>
          }

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">New Category Name</span>
              <OverlayTrigger
                    overlay={
                      <Tooltip >
                        Enter the name of New Category
                      </Tooltip>
                    }
                  >
                    <i className="fas fa-exclamation-circle ms-2 fs-7" aria-label="Enter the name of New Collection"></i>
                </OverlayTrigger>
            </label>
            <input
              type="text"
              name="name"
              className="form-control form-control-solid"
              value={ category.name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className='text-center pt-15'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
              >
                Discard
              </button>

              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={() => this._handleSave()}
              >
                {buttonTitle}
              </button>
            </div>
          </div>


        </Modal.Body>
        
      </Modal>
    )
  }
}
