import React from "react"
import StatCard from "./components/common/StatCard";
import Card from "./components/structure/Card";
import CustomersTable from "./components/tables/CustomersTable";

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      dateFrom: null,
      dateTo: null,
      currency: 0
    }
  }

  render() {
    let {
      dateFrom,
      dateTo
    } = this.state

    return (
      <>
        <div className="g-5 gx-xxl-8">
          <div className="row">
            <StatCard
              title="Total Installs"
              count={342}
            />
            <StatCard
              title="iOS Installs"
              count={269}
            />
            <StatCard
              title="Android Installs"
              count={73}
            />
            <StatCard
              title="Pro Total"
              count={"342 (€2,394 pm)"}
            />
            <StatCard
              title="iOS Pro Installs"
              count={269}
            />
            <StatCard
              title="Android Pro Installs"
              count={73}
            />
          </div>

          <div className="row gy-5 g-xl-8">
            <div className="col">
              <Card
                title="Installs By Date"
                subtitle="Date wise Total Installs from both iOS and Android"
              >

              </Card>
            </div>
          </div>

          <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
              <CustomersTable
                title="Users"
                subtitle="Over 50+ Users"
                showFilter={false}
                latestResults={true}
              />
            </div>
          </div>
        </div>
      </>
    )

  }

}
