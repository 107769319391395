import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import General from "../../../../utils/General";

export default class LinkCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      link: props.link
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      link
    } = this.state

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <CopyToClipboard
          text={link}
          onCopy={() => General.showNotification("Referral Link Copied", true)}
          className=" btn btn-icon btn-light-primary btn-hover-primary btn-sm min-w-auto me-1 edit"
        >
          <a className=" btn btn-icon btn-light-primary btn-hover-primary btn-sm min-w-auto me-1 edit">
            <span className="svg-icon svg-icon-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M14,16 L12,16 L12,12.5 C12,11.6715729 11.3284271,11 10.5,11 C9.67157288,11 9,11.6715729 9,12.5 L9,17.5 C9,19.4329966 10.5670034,21 12.5,21 C14.4329966,21 16,19.4329966 16,17.5 L16,7.5 C16,5.56700338 14.4329966,4 12.5,4 L12,4 C10.3431458,4 9,5.34314575 9,7 L7,7 C7,4.23857625 9.23857625,2 12,2 L12.5,2 C15.5375661,2 18,4.46243388 18,7.5 L18,17.5 C18,20.5375661 15.5375661,23 12.5,23 C9.46243388,23 7,20.5375661 7,17.5 L7,12.5 C7,10.5670034 8.56700338,9 10.5,9 C12.4329966,9 14,10.5670034 14,12.5 L14,16 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.500000, 12.500000) rotate(-315.000000) translate(-12.500000, -12.500000) "/>
              </g>
            </svg>
          </span>
          </a>
        </CopyToClipboard>
      </Tooltip>
    )
  }
}

LinkCell.defaultProps = {
  tooltip: 'Copy Link'
}