import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Switch from "react-switch";
import Alert from 'react-bootstrap/Alert'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";
import Email from "../../../utils/Email";


export default class Customer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      error: null,
      ...this._getState(props),
    }
  }

  componentDidMount() {
    Backend.getLists("system_custom").then(lists => {
      this.setState({lists})
    }).catch(e => General.showNotification(e.message, false))
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _getState(props){
    return {
      ...props,
      show: props.show,
      lists: [],
      customer: props.customer ? props.customer : {user:{}, list_ids: []},
    }
  }

  _handleChange(e) {
    let customer = this.state.customer
    customer.user[e.target.name] = e.target.value
    this.setState({ customer, error: null })
  }

  _isFormValid() {
    let { first_name, last_name, email, password } = this.state.customer.user

    let error = null

    if(first_name == null || first_name.length === 0){
      error = "Please enter the user first name"
    }else if(last_name == null || last_name.length === 0){
      error = "Please enter the user last name"
    }else if (!Email.isValid(email)) {
      error = 'Please enter a valid user email address'
    }

    if(!this.state.customer.user.id){
      if(password == null || password.length === 0){
        error = 'Please enter a valid user password'
      }
    }

    if(error){
      General.showNotification(error, false)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      customer
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    if(customer.user.id){
      this._updateUser(customer)
    }else{
      this._addUser(customer)
    }
  }

  _addUser(user){
    Backend.addCustomer(user).then(user => {
      this.setState({ loading: false })
      this.props.onSaved(user)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  _updateUser(user){
    Backend.updateCustomer(user).then(user => {
      this.setState({ loading: false })
      this.props.onSaved(user)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  render() {
    let {
      show,
      loading,
      customer,
      error,
      lists
    } = this.state

    let title = customer.user?.id ? "Update User" : "Add New User"
    let buttonTitle = customer.user?.id ? "Update" : "Save"

    return (
      <Modal
        show={show}
        className="add-new-linkie"
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 my-7'
        >

          { error &&
            <Alert variant={"danger"}>
              { error }
            </Alert>
          }

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">First Name</span>
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-control-solid"
              value={ customer.user.first_name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Last Name</span>
            </label>
            <input
              type="text"
              name="last_name"
              className="form-control form-control-solid"
              value={ customer.user.last_name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">Email</span>
            </label>
            <input
              type="text"
              name="email"
              className="form-control form-control-solid"
              value={ customer.user.email }
              onChange={e => this._handleChange(e)}
            />
          </div>

          {
            !customer.user.id &&
            <div className="d-flex flex-column mb-7 fv-row">
              <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span className="required">Password</span>
              </label>
              <input
                type="password"
                name="password"
                className="form-control form-control-solid"
                value={ customer.user.password }
                onChange={e => this._handleChange(e)}
              />
            </div>
          }

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="">Featured</span>
            </label>
            <Switch
              onChange={checked => {
                customer.featured = checked
                this.setState({ customer })
              }}
              checked={customer.featured}
              aria-labelledby="neat-label"
              onColor="#333333"
              offColor="#F3F6F9"
              checkedIcon={null}
              uncheckedIcon={null}
              className="c-switch"
            />
          </div>

          <hr className="mb-7 dashed"/>

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="fs-6 fw-bold form-label mb-2">
              Collections<br/>
              <small>Please choose which collections you want to add the Website to</small>
            </label>
            <div className="lists">
              <div className="row">
                <div className="col">
                  {
                    lists.map(list => (
                      <>
                        <input
                          type="checkbox"
                          className="btn-check"
                          id={`list-${list.id}`}
                          autoComplete="off"
                          checked={customer.list_ids?.includes(list.id)}
                          onChange={e => {
                            let index = customer.list_ids.indexOf(list.id)
                            if(index !== -1){
                              customer.list_ids.splice(index, 1)
                            }else{
                              customer.list_ids.push(list.id)
                            }
                            this.setState({customer})
                          }}
                        />
                        <label className="btn btn-light-primary btn-sm" htmlFor={`list-${list.id}`}>{list.name}</label>
                      </>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='text-center pt-15'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
              >
                Discard
              </button>

              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={() => this._handleSave()}
              >
                {buttonTitle}
              </button>
            </div>
          </div>


        </Modal.Body>

      </Modal>
    )
  }
}
