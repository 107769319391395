import React from "react";

import General from '../../../utils/General'
import Event from "../../../utils/Event";
import ListLinkie from "../modals/ListLinkie";
import Confirmation from "../modals/Confirmation";
import Backend from "../../../utils/Backend";

import Url from "../../../utils/Url";

export default class Linkie extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      type: props.type,
      list: props.list,
      linkie: props.linkie,
      faviconFailed: false
    }
    this.list = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _deleteLinkie(){
    let {
      list,
      selectedLinkie
    } = this.state

    Backend.deleteLinkie(selectedLinkie, list).then(() => {
      General.showNotification("Website Deleted")
      this.setState({
        showConfirmModal: false,
        selectedLinkie: null
      }, () => this.props.refresh())
    }).catch(e => {
      General.showNotification(e.message, false)
      this.setState({
        showConfirmModal: false,
        selectedLinkie: null
      })
    })
  }

  _renderPlaceholder(name){
    return <span>{name.charAt(0)}</span>
  }

  _renderIcon(url){
    let favicon = Url.getFavIcon(url)

    return (
      <img
        src={favicon}
        onError={() => {this.setState({faviconFailed: true})}}
      />
    )
  }

  render(){
    let {
      type,
      linkie,
      faviconFailed,
      selectedLinkie,
      showConfirmModal,
      showListLinkieModal
    } = this.state

    let url = linkie.url
    if(url.startsWith("https://")){
      url = url.replace("https://", "")
    }else if(url.startsWith("http://")){
      url = url.replace("http://", "")
    }

    if(url.length > 50){
      url = url.slice(0, 50) + "..."
    }

    let name = linkie.name
    let fullUrl = linkie.url

    return (
      <>
        <div className="col-auto symbol">
          <span>
            {
              faviconFailed ? this._renderPlaceholder(name) : this._renderIcon(fullUrl)
            }
          </span>
        </div>
        <div className="col link">
          <a
            href={fullUrl}
            className="name text-gray-800 text-hover-primary fs-6 fw-bolder"
            onClick={e => e.preventDefault()}
          >
            {name}
          </a>
          <span className="url fw-bold d-block fs-7">{url}</span>
        </div>
        <div className="col-auto buttons">
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm w-80px fs-6 mx-2"
              onClick={() => {
                this.setState({
                  showListLinkieModal: true,
                  selectedLinkie: linkie
                })
              }}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm w-80px fs-6"
              onClick={() => this.setState({
                showConfirmModal: true,
                selectedLinkie: linkie
              })}
            >
              Delete
            </button>
          </>
        </div>
        {
          showListLinkieModal &&
          <ListLinkie
            type={type}
            show={showListLinkieModal}
            linkie={selectedLinkie}
            onHide={() => {
              this.setState({
                showListLinkieModal: false,
                selectedLinkie: null
              }, () => this.props.refresh())
            }}
            onSaved={() => {
              this.setState({
                showListLinkieModal: false,
                selectedLinkie: null
              }, () => this.props.refresh())
            }}
          />
        }
        {
          showConfirmModal &&
          <Confirmation
            show={showConfirmModal}
            onHide={() => {
              this.setState({ showConfirmModal: false })
            }}
            onConfirm={() => this._deleteLinkie()}
          />
        }
      </>
    )
  }
}
