import React from "react"

import ReactTable from "react-table"
import "react-table/react-table.css"

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";

import General from "../../../utils/General";
import FetchHelper from "../../../utils/FetchHelper";
import Card from "../structure/Card";

const OBJECTS_FILTERS = {
  name: {
    api: "objects",
    display: "Status"
  },
  values: [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Hidden",
      value: "deleted"
    }
  ]
}

export default class BaseTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      data: [],
      pagesNo: 0,
      searchTerm: "",
      dates: [moment().subtract(7, 'd'), moment()],
      csvData: [],
      filterValue: {objects: "active"},
      searchFilter: this.props.searchFilter,
      filters: this.props.filters ? [...this.props.filters, OBJECTS_FILTERS] : [OBJECTS_FILTERS],
    }

    this.reactTable = React.createRef();
  }

  refresh() {
    let current = this.reactTable.current;
    if (current) {
      current.state.page = 0
      this.setState({
        loading: false
      }, () => this._fetchData(current.state, current))
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    this.refresh()
  }, 500)

  _handleFilterChange(event){
    let { filterValue } = this.state

    filterValue[event.target.name] = event.target.value
    this.setState({filterValue}, () => this._handleSearch())
  }

  _getUrl(endpoint, state){

    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.state.searchTerm
    }

    let sorted = state.sorted[0]
    if(sorted){
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, "__")
      if(sorted.desc){
        orderBy = `-${orderBy}`
      }
      params["order_by"] = orderBy
    }

    if(this.state.filterValue){
      Object.entries(this.state.filterValue).map(filter => {
        params[filter[0]] = filter[1]
      })
    }

    if(this.props.dateRange){
      params["date_from"] = this.state.dates[0].format("YYYY-MM-DD")
      params["date_to"] = this.state.dates[1].format("YYYY-MM-DD")
    }

    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state)

    if(this.props.endpoint === ""){
      this.setState({
        data: this.props.mockData,
        pagesNo: 1,
        loading: false
      });
      return
    }
    FetchHelper.get(url, false)
      .then(response => {
        this.setState({
          data: response.results,
          pagesNo: Math.ceil(response.count / state.pageSize),
          totalRecords: response.count,
          loading: false
        });
      })
      .catch(error => {

      })
  }

  _addParams(url, params){
    if(Object.keys(params).length == 0){
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if(url.indexOf("?") == -1){
      url += "?"
    }
    else if(!url.endsWith("&")){
      url += "&"
    }

    Object.keys(params).forEach(function(key) {
      url += key+"="+params[key]+"&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url
  }

  _renderHeaderLeftContent(){

  }

  _renderFilter(){
    if(!this.props.showFilter){
      return null
    }
    return (
      <div className="row card-header border-0 pt-5 justify-content-start">
        <div className="col-lg my-1">
          <div className="w-100 position-relative">
            <span
              className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                      transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"></path>
              </svg>
            </span>
            <input
              type="text"
              className="form-control px-15"
              name="search"
              onChange={e => {
                this.setState({
                  searchTerm: e.target.value
                }, () => this._handleSearch())
              }}
              placeholder="Search..."
              data-kt-search-element="input"
            />
          </div>
        </div>

        {
          this.props.dateRange &&
          <div className="col-lg my-1 ">
            <DateRangePicker
              value={this.state.dates}
              onChange={dateRange => {
                this.setState({
                  dates: [moment(dateRange[0]), moment(dateRange[1])]
                }, () => this._handleSearch())
              }}
              className={"form-control"}
            />
          </div>
        }

        {
          this.props.filters &&
          this.props.filters.map(filter => {
            return (
              <div className="col-lg my-1 ">
                <div className="select-wrapper w-100">
                  <select
                    className="form-control"
                    name={filter.name.api}
                    onChange={e => {
                      this._handleFilterChange(e)
                    }}
                  >
                    {
                      filter.values.map(filterOptions => {
                        return (
                          <option value={filterOptions.value || ""}>
                            {filterOptions.label}
                          </option>
                        )
                      })
                    }
                    {/*<option selected="">Type</option>*/}
                    {/*<option value="climate">Type 1</option>*/}
                    {/*<option value="crypto">Type 2</option>*/}
                  </select>
                </div>
              </div>
            )
          })

        }
      </div>
    )
  }

  render() {
    const {data, pagesNo, loading, totalRecords} = this.state;

    return (
      <>
        <Card
          title={this.props.title}
          subtitle={this.props.subtitle}
          showHeader={this.props.showHeader}
          renderToolbar={this.props.renderToolbar}
          renderFilter={() => this._renderFilter()}
        >
          <div className="table-responsive">
            <ReactTable
              ref={this.reactTable}
              manual
              data={data}
              pages={pagesNo}
              totalRecords={totalRecords}
              loading={loading}
              onFetchData={this._fetchData.bind(this)}
              columns={this.props.columns}
              getTdProps={this.props.getTdProps}
              getTrProps={this.props.getTrProps}
              getTheadProps={this.props.getTheadProps}
              getTheadThProps={this.props.getTheadThProps}
              showPagination={this.props.showPagination}
              showPaginationTop={this.props.showPaginationTop}
              showPaginationBottom={this.props.showPaginationBottom}
              defaultSorted={this.props.defaultSorted}
              SubComponent={this.props.SubComponent}
              NoDataComponent={() => {
                return (
                  <div className="rt-noData">
                    { this.props.noDataMessage}
                  </div>
                )
              }}
            />
          </div>
        </Card>
      </>
    )
  }

}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  searchPlaceholder: "Search...",
  showHeader: true,
  showFilter: true,
  icon: null,
  filters: null,
  searchFilter: null,
  searchFilterName: "name",
  noDataMessage: "No documents found",
  showSearch: true,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  exportButtonsEnabled: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null,
  renderFilter: () => null,
}

class Loading extends React.Component {
  render () {
    return (
      this.props.loading
        ? <div className='-loading -active'>
          <div className='-loading-inner'>
          </div>
        </div>
        : null
    )
  }
}
