import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Event from "../../../utils/Event";
import Linkie from "../common/Linkie";
import ListLinkie from "../modals/ListLinkie";

export default class Websites extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      linkies: [],
      type: props.type,
      selectedWebsite: {list_ids: []}
    }
  }

  componentDidMount() {
    let {
      type,
      selectedWebsite
    } = this.state

    if(type === "system_popular"){
      Backend.getLists("system_popular").then(lists => {
        this.setState({lists}, () => {
          selectedWebsite.list_ids.push(lists[0].id)
          this.setState({selectedWebsite, selectedList: lists[0]})
        })
      }).catch(e => General.showNotification(e.message, false))
    }
    this._setUpEvents()
  }

  _getLinkies(){
    let {
      type,
      selectedList
    } = this.state

    let endpoint = `${window.Api.Linkies}?paginated=false${selectedList.label !== "All" ? `&list_id=${selectedList.id}` : `&list_type=${type}`}`

    Backend.getLinkies(endpoint).then(linkies => this.setState({linkies}))
  }

  _setUpEvents(){
    Event.on('LIST_CHANGE', (selectedList) => {
      this.setState({selectedList}, () => this._getLinkies())
    })
    Event.on('SHOW_LINKIE_MODAL', (selectedList) => {
      let selectedWebsite = this.state.selectedWebsite
      if(selectedList.id && this.state.type !== "system_popular"){
        selectedWebsite = {list_ids: [selectedList.id]}
      }
      this.setState({showWebsiteModal: true, selectedWebsite})
    })
  }

  _onDragEnd(result) {
    let {
      selectedList
    } = this.state

    if (!result.destination) {
      return;
    }

    const linkies = reorder(
      this.state.linkies,
      result.source.index,
      result.destination.index
    );

    let data = {linkies: linkies}

    this.setState({linkies})

    Backend.updateLinkies(selectedList.id, data).then(linkies => {
      General.showNotification("Collection Updated")
    }).catch(e => General.showNotification(e.message, false))
  }

  _renderLinkieHandle(linkie, index){
    return (
      <Draggable key={linkie.id} draggableId={linkie.id.toString()} index={index}>
        {(provided, snapshot) => (
          <div
            className="row linkie"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {this._renderLinkie(linkie, index)}
          </div>
        )}
      </Draggable>
    )
  }

  _renderLinkie(linkie, index){
    let {
      selectedList,
      type
    } = this.state

    return (
      <Linkie
        key={index}
        type={type}
        linkie={linkie}
        list={selectedList}
        refresh={() => this._getLinkies()}
      />
    )
  }

  render() {
    let {
      type,
      linkies,
      selectedList,
      selectedWebsite,
      showWebsiteModal,
    } = this.state

    if(!selectedList) return null

    let canOrder = selectedList.label !== "All"

    return (
      <>
        {
          canOrder ?
            <div className="linkies">
              <DragDropContext onDragEnd={result => this._onDragEnd(result)}>
                <Droppable droppableId={`droppable${type}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {linkies.map((linkie, index) => this._renderLinkieHandle(linkie, index))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          :
            linkies.map((linkie, index) => (
              <div className="row linkie">
                {this._renderLinkie(linkie, index)}
              </div>
            ))
        }

        {
          showWebsiteModal &&
          <ListLinkie
            show={showWebsiteModal}
            type={type}
            linkie={selectedWebsite}
            onSaved={() => {
              this.setState({showWebsiteModal: false, selectedWebsite: null})
              this._getLinkies()
            }}
            onHide={() => this.setState({showWebsiteModal: false, selectedWebsite: null})}
          />
        }
      </>
    )

  }

}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#fbfbfb" : 'transparent',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid,
  background: isDragging ? "rgba(93, 120, 255, 0.4)" : "transparent",
  outline: 'none',
  ...draggableStyle
});