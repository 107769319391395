import React from 'react'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Alert from 'react-bootstrap/Alert'

import Backend from "../../../utils/Backend"
import General from "../../../utils/General";

export default class Confirmation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 '
        >

          <div className='text-center pt-5'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
              >
                Dismiss
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.props.onConfirm()}
              >
                Confirm
              </button>
            </div>
          </div>


        </Modal.Body>
        
      </Modal>
    )
  }
}
