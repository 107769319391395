import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import BaseTable from "./BaseTable"
import Customer from "../modals/Customer";
import EditCell from "./cells/EditCell";
import LinkCell from "./cells/LinkCell";

const TYPE_FILTERS = {
  name: {
    api: 'user_type',
    display: 'type',
  },
  values: [
    {
      label: 'All Users',
      value: "",
    },
    {
      label: 'Non-Registered Users',
      value: 'anonymous',
    },
    {
      label: 'Registered Users',
      value: 'registered',
    },
    {
      label: 'Pro Users',
      value: 'pro',
    },
  ],
}

class CustomersTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _getColumns(){
    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: customer => moment(customer.created_at).format('DD MMM YYYY') || '-'
      },
      {
        Header: 'Name',
        id: 'user.first_name',
        accessor: customer => `${customer.user.first_name} ${customer.user.last_name}`
      },
      {
        Header: 'Email',
        id: 'user.email',
        accessor: customer => `${customer.user.email}`
      },
      {
        Header: 'Swipes',
        id: 'swipes_no',
        accessor: 'swipes_no'
      },
      {
        Header: '# of Collections',
        id: 'lists_no',
        accessor: 'lists_no'
      },
      {
        Header: '# of Websites',
        id: 'linkies_no',
        accessor: 'linkies_no'
      },
      {
        Header: 'Actions',
        sortable: false,
        exportable: false,
        headerClassName: 'text-end',
        Cell: rowInfo => {
          let customer = rowInfo.original
          return (
            <div className="text-end">
              <EditCell
                onEditClicked={() => this.setState({showCustomerModal: true, selectedCustomer: customer})}
              />
            </div>
          )
        }
      }
    ]
  }

  render() {
    let {
      selectedCustomer,
      showCustomerModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Customers}`}
          filters={[TYPE_FILTERS]}
          noDataMessage={"No users found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPaginationBottom={!this.props.latestResults}
          dateRange={this.props.dateRange}
          showFilter={this.props.showFilter}
          renderToolbar={() => (
            <>
              <div className="card-toolbar">
                <button
                  type="button"
                  className="btn btn-sm btn-outline btn-outline-dark btn-outline-dark btn-mw"
                  data-bs-toggle="" data-bs-target=""
                  onClick={() => this.setState({showUserModal: true})}
                >
                  <span>Add User</span>
                </button>
              </div>
            </>
          )}
        />
        {
          showCustomerModal &&
          <Customer
            show={showCustomerModal}
            customer={selectedCustomer}
            onSaved={() => {
              this.setState({showCustomerModal: false, selectedCustomer: null})
              this.table.current.refresh()
            }}
            onHide={() => this.setState({showCustomerModal: false, selectedCustomer: null})}
          />
        }
      </>
    )

  }

}

CustomersTable.defaultProps = {
  showFilter: true,
  latestResults: false
}

export default withRouter(CustomersTable);
