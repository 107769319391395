import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ReactTableDefaults } from 'react-table';
import { toast } from 'react-toastify';

import './App.css';

import './assets/css/plugins.bundle.css'
import './assets/css/style.bundle.css'
import './assets/css/custom.css'
import './assets/scss/app.scss'

import Api from './constants/Api'
import General from './constants/General'

import $ from 'jquery'

import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'

import Pagination from "./pages/components/tables/Pagination";

import AuthApp from './pages/App'
import Login from "./pages/Login";

Object.assign(ReactTableDefaults, {
  minRows: 0,
  PaginationComponent: Pagination
})

toast.configure()

export default class App extends React.Component {

  componentDidMount() {
    let scripts = []

    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/plugins.bundle.js')
    )
    scripts.push(
      this._getScript(process.env.PUBLIC_URL + '/assets/js/scripts.bundle.js')
    )

    setTimeout(() => {
      this._addScripts(scripts)
    }, 300)
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <UnauthenticatedRoute exact path="/login" component={Login} />
          <AuthenticatedRoute path="/" component={AuthApp} />
        </Switch>
      </BrowserRouter>
    )
  }

}
