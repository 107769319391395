import React from "react"
import StatCard from "./components/common/StatCard";
import Card from "./components/structure/Card";
import CustomersTable from "./components/tables/CustomersTable";

export default class Customers extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      dateFrom: null,
      dateTo: null,
      currency: 0
    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="g-5 gx-xxl-8">
          <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
              <CustomersTable
                title="Users"
                subtitle="Over 50+ Users"
                dateRange={true}
              />
            </div>
          </div>
        </div>
      </>
    )

  }

}
