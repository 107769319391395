import React from "react"

export default class StatCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      title: props.title,
      count: props.count
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      count
    } = this.state

    return (
      <>
        <div className="col-xl-4">
          <a href="javascript:;" className="card card-custom bg-primary bg-hover-state-primary card-stretch mb-8">
            <div className="card-body">
              <span className="svg-icon svg-icon-white svg-icon-3x me-n1">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                     height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"/>
                      <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"/>
                      <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"/>
                      <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"/>
                  </g>
                  </svg>
              </span>
              <div className="text-inverse-primary fw-bolder fs-4 mb-2 mt-5">{count}</div>
              <div className="fw-bold text-inverse-primary fs-7">{title}</div>
            </div>
          </a>
        </div>
      </>
    )
  }

}