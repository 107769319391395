import React from 'react'
import PropTypes from 'prop-types'

const defaultButton = props => <button {...props}>{props.children}</button>

export default class Pagination extends React.Component {
  constructor(props) {
    super()

    this.changePage = this.changePage.bind(this)

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    }
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      })
    }

    this.changePage(nextProps.page + 1)
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages)
  }

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }

  changePage(page) {
    const activePage = this.props.page + 1

    if (page === activePage) {
      return
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages)

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    })

    this.props.onPageChange(page - 1)
  }

  render() {
    const {
      PageButtonComponent = defaultButton,
      showPageSizeOptions,
      onPageSizeChange,
      pageSize,
      data,
      totalRecords,
      pageSizeOptions
    } = this.props
    const { visiblePages } = this.state
    const activePage = this.props.page + 1

    let startRecord = this.props.pageSize * this.props.page + 1
    if(data.length === 0){
      startRecord = 0
    }

    let endRecord = this.props.pageSize * (this.props.page + 1)
    if(endRecord > totalRecords){
      endRecord = totalRecords
    }
    return (
      <div className="d-flex flex-stack flex-wrap py-5">

        <div className="d-flex flex-stack flex-wrap mb-5 mb-md-0">
          <div class="select-wrapper">
            <select
              className="form-select w-auto"
              onChange={e => onPageSizeChange(Number(e.target.value))}
              value={pageSize}
            >
              {pageSizeOptions.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div class="fs-6 fw-bold text-gray-700 px-5">
            Showing {startRecord} -{endRecord} of {totalRecords}
          </div>
        </div>  

        <ul class="pagination">

          <li class="page-item previous from-start">
            <a
              href='javascript:void(0)'
              className="page-link"
              onClick={() => {
                if (activePage === 1) return
                this.changePage(1)
              }}
              disabled={activePage === 1}
            >
              <i class="previous first"><i class="previous"></i></i>
            </a>
          </li>
          
          <li class="page-item previous">
            <a
              href='javascript:void(0)'
              className="page-link"
              onClick={() => {
                if (activePage === 1) return
                this.changePage(activePage - 1)
              }}
              disabled={activePage === 1}
            >
              <i class="previous"></i>
            </a>
          </li>
          
          {visiblePages.map((page, index, array) => {
            return (
              <li 
                className={
                  activePage === page
                    ? 'page-item active'
                    : 'page-item'
                }
              >
                <a
                  key={page}
                  className="page-link"
                  href='javascript:void(0)'
                  onClick={this.changePage.bind(null, page)}
                >
                  {array[index - 1] + 2 < page ? `${page}` : page}
                </a>
              </li>
            )
          })}

          <li class="page-item next">
            <a
              href='javascript:void(0)'
              className="page-link"
              onClick={() => {
                if (activePage === this.props.pages) return
                this.changePage(activePage + 1)
              }}
              disabled={activePage === this.props.pages}
            >
              <i class="next"></i>
            </a>
          </li>

          <li class="page-item next to-end">
            <a
              href='javascript:void(0)'
              className="page-link"
              onClick={() => {
                if (activePage === this.props.pages) return
                this.changePage(this.props.pages)
              }}
              disabled={activePage === this.props.pages}
            >
              <i class="next first"><i class="next"></i></i>
            </a>
          </li>

        </ul>

      </div>
    )
  }
  
}
