import React from "react"
import StatCard from "./components/common/StatCard";
import Card from "./components/structure/Card";
import PartnersTable from "./components/tables/PartnersTable";

export default class Partners extends React.Component {

  constructor(props) {
    super(props)

    window.General.StatStatus = false

    this.state = {
      dateFrom: null,
      dateTo: null,
      currency: 0
    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="g-5 gx-xxl-8">
          <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
              <PartnersTable
                title="Partners"
                subtitle="Over 50+ Partners"
              />
            </div>
          </div>
        </div>
      </>
    )

  }

}