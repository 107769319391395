import React from "react"
import { withRouter } from "react-router-dom";
// import AsyncSelect from "../common/AsyncSelect";

import Event from "../../../utils/Event";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import { IconLinkie } from "../../../assets/media/svg/logo/icon/linkie"
import General from "../../../utils/General";

const LINK_DASHBOARD = {
  title: "Dashboard",
  icon: (
    <>
      <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"/>
      <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"/>
      <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"/>
      <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"/>
    </>
  ),
  url: "/",
}

const LINK_USERS = {
  title: "Users",
  icon: (
    <>
      <path
        d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
        fill="black"/>
      <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="black"/>
      <path
        d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
        fill="black"/>
      <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="black"/>
    </>
  ),
  url: "/users",
}

const LINK_REFERRAL_PARTNERS = {
  title: "Referral Partners",
  icon: (
    <>
      <path
        d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
        fill="black"/>
      <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="black"/>
      <path
        d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
        fill="black"/>
      <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="black"/>
    </>
  ),
  url: "/referral-partners",
}

const LINK_ONBOARDING_COLLECTIONS = {
  title: "Onboarding Collections",
  icon: (
    <>
      <path opacity="0.3"
            d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z"
            fill="black"/>
      <path d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z" fill="black"/>
    </>
  ),
  url: "/onboarding-collections",
}

const LINK_ONBOARDING_WEBSITES = {
  title: "Onboarding Websites",
  icon: (
    <>
      <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
            fill="currentColor"/>
      <path opacity="0.3"
            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
            fill="currentColor"/>
    </>
  ),
  url: "/onboarding-websites",
}

const LINK_FIXED_COLLECTIONS = {
  title: "Fixed Collections",
  icon: (
    <>
      <path opacity="0.3"
            d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z"
            fill="black"/>
      <path d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z" fill="black"/>
    </>
  ),
  url: "/fixed-collections",
}

const LINK_FIXED_WEBSITES = {
  title: "Fixed Websites",
  icon: (
    <>
      <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
            fill="currentColor"/>
      <path opacity="0.3"
            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
            fill="currentColor"/>
    </>
  ),
  url: "/fixed-websites",
}

const LINK_POPULAR_WEBSITES = {
  title: "Trending Websites",
  icon: (
    <>
      <path opacity="0.3"
            d="M7.16973 20.95C6.26973 21.55 5.16972 20.75 5.46972 19.75L7.36973 14.05L2.46972 10.55C1.56972 9.95005 2.06973 8.55005 3.06973 8.55005H20.8697C21.9697 8.55005 22.3697 9.95005 21.4697 10.55L7.16973 20.95Z"
            fill="black"/>
      <path
        d="M11.0697 2.75L7.46973 13.95L16.9697 20.85C17.8697 21.45 18.9697 20.65 18.6697 19.65L13.1697 2.75C12.7697 1.75 11.3697 1.75 11.0697 2.75Z"
        fill="black"/>
    </>
  ),
  url: "/trending-websites",
}

const LINK_REPORTING = {
  title: "Reporting",
  icon: (
    <>
      <path opacity="0.3"
            d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
            fill="black"/>
      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
    </>
  ),
  url: "/reporting",
}

const LINK_SETTINGS = {
  title: "Settings",
  icon: (
    <>
      <path opacity="0.3"
            d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
            fill="black"/>
      <path
        d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
        fill="black"/>
    </>
  ),
  url: "/settings",
}

const SECTIONS = [
  {
    title: null,
    links: [
      LINK_DASHBOARD,
      LINK_USERS,
      LINK_REFERRAL_PARTNERS,
      LINK_ONBOARDING_COLLECTIONS,
      LINK_ONBOARDING_WEBSITES,
      LINK_FIXED_COLLECTIONS,
      LINK_FIXED_WEBSITES,
      LINK_POPULAR_WEBSITES,
      LINK_REPORTING,
      LINK_SETTINGS
    ]
  }
]

class LeftMenu extends React.Component {
  constructor(props){
    super(props)

    let sections = SECTIONS

    this.state = {
      sections,
      currentUser: AuthManager.currentUser,
      selectedList: null
    }
  }

  componentDidMount() {
    this._setUpEvents()
  }

  _setUpEvents(){
    Event.on('LIST_CHANGE', (list) => {
      this.setState({selectedList: list})
    })
  }

  _goTo(url){
    this.props.history.push(url)
  }

  _renderSections() {
    let {sections, isLoading} = this.state;

    return (
      <>
        {
          sections.map(section => {
            return (
              section.links.map(link => {
                let className = "menu-link";
                if (link.url === window.location.pathname) {
                  className += " active";
                }
                return (
                  <>
                    <div className="menu-item">
                      <a className={className} href={link.url}>
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              {link.icon}
                            </svg>
                          </span>
                        </span>
                        <span className="menu-title">
                          {link.title}
                        </span>
                      </a>
                    </div>
                  </>
                )
              })
            )
          })
        }
      </>
    )

  }

  render() {
    let {
      currentUser,
      selectedList,
      showListModal,
      showLinkieModal,
    } = this.state

    // if (!selectedList) return null

    // let value = {value: selectedList.id, label: selectedList.name, data: selectedList}

    return (
      <>
        <div id="kt_aside" className="aside aside-light aside-hoverable" data-kt-drawer="true"
             data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}"
             data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
             data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle"
             data-select2-id="select2-data-kt_aside">
          <div className="aside-logo flex-column-auto" id="kt_aside_logo">
            <a href="/">
              <IconLinkie className="w-auto mh-65px icon-linkie" />
            </a>
          </div>
          <div className="aside-menu flex-column-fluid">
            <div
              className="hover-scroll-overlay-y my-5 my-lg-5" 
              id="kt_aside_menu_wrapper" 
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}" 
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" 
              data-kt-scroll-wrappers="#kt_aside_menu"
              data-kt-scroll-offset="0"
            >

              <div
                className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="#kt_aside_menu" data-kt-menu="true" data-kt-menu-expand="false">

                {this._renderSections()}

              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(LeftMenu);