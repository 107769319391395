import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Dropdown from 'react-bootstrap/Dropdown';

// import LazyLoadingList from '../../components/common/LazyLoadingList';
// import AuthManager from '../../../utils/AuthManager'
import General from '../../../utils/General'
// import Event from "../../../utils/Event";
import ListModal from "../modals/List";
import Confirm from "../modals/Confirmation";
import Backend from "../../../utils/Backend";
// import Notify from "../../../utils/Notify";
// import FetchHelper from "../../../utils/FetchHelper";
// import Url from "../../../utils/Url";

export default class List extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      list: props.list,
    }
    this.list = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _deleteList() {
    let {
      list
    } = this.state

    Backend.deleteList(list).then(() => {
      General.showNotification("Collection Deleted")
      this.setState({
        showConfirmModal: false,
        selectedList: null
      })
      this.props.refresh()
    }).catch(e => {
      General.showNotification(e.message, false)
      this.setState({
        showConfirmModal: false,
        selectedList: null
      })
    })
  }


  render() {
    let {
      list,
      selectedList,
      showConfirmModal,
      showListModal
    } = this.state

    const ListMenu = React.forwardRef(({ children, onClick }, ref) => (
      <>
        <button
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <i className="bi bi-three-dots fs-4"></i>
        </button>
        {children}
      </>
    ));

    return (
      <>


        <div className="list card">
          <div className="card-body">
            <div className="name text-gray-800 text-hover-primary fs-6 fw-bolder">{list.name}</div>
            <span className="url fw-bold d-block fs-7">{list.no_linkies} Websites</span>
            {/* begin::Menu */}
            <Dropdown
              className="c-menu"
            >
              <Dropdown.Toggle
                as={ListMenu}
              />
              <Dropdown.Menu
                align="start"
              >
                <Dropdown.Item 
                  href=""
                  onClick={(e) => {
                    this.setState({
                      showListModal: true,
                      selectedList: list
                    })
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item 
                  href=""
                  onClick={(e) => {
                    this.setState({
                      showConfirmModal: true,
                      selectedList: list
                    })
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* end::Menu */}
          </div>
        </div>
        {
          showListModal &&
          <ListModal
            show={showListModal}
            list={selectedList}
            onHide={() => {
              this.setState(
                {
                  showListModal: false,
                  selectedList: null
                }, 
                // () => this.props.refresh()
              )
            }}
            onSaved={() => {
              this.setState(
                {
                  showListModal: false,
                  selectedList: null
                }, 
                () => this.props.refresh()
              )
            }}
          />
        }
        {
          showConfirmModal &&
          <Confirm
            show={showConfirmModal}
            onHide={() => {
              this.setState({ showConfirmModal: false })
            }}
            onConfirm={() => this._deleteList()}
          />
        }
      </>
    )
  }
}
