import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static addCategory(category){
    let data = JSON.parse(JSON.stringify(category))
    return FetchHelper.post(window.Api.Lists, data)
  }

  static updateCategory(category){
    let data = JSON.parse(JSON.stringify(category))
    return FetchHelper.patch(`${window.Api.Lists}/${category.id}`, data)
  }

  static deleteCategory(category){
    return FetchHelper.delete(`${window.Api.Lists}/${category.id}`)
  }

  static addWebsite(bookmark){
    let data = JSON.parse(JSON.stringify(bookmark))
    if(data.type){
      data.type = data.type.id
    }
    return FetchHelper.post(window.Api.Linkies, data)
  }

  static updateWebsite(bookmark){
    let data = JSON.parse(JSON.stringify(bookmark))
    if(data.type){
      data.type = data.type.id
    }
    return FetchHelper.patch(`${window.Api.Linkies}/${bookmark.id}`, data)
  }

  static deleteWebsite(bookmark){
    return FetchHelper.delete(`${window.Api.Linkies}/${bookmark.id}`)
  }

  static getCategories(selectable){
    return FetchHelper.get(`${window.Api.Types}?paginated=false&selectable=${selectable}`)
  }

  static getList(list_id){
    return FetchHelper.get(`${window.Api.Lists}/${list_id}`)
  }

  static getLinkie(linkieID){
    return FetchHelper.get(`${window.Api.Linkies}/${linkieID}`)
  }

  static getLinkies(endpoint){
    return FetchHelper.get(endpoint)
  }

  static updateLinkies(list_id, data){
    data = JSON.parse(JSON.stringify(data))
    data.linkie_ids = data.linkies.map(link => link.id)
    return FetchHelper.patch(`${window.Api.Lists}/${list_id}`, data)
  }

  static saveLastList(data){
    data = JSON.parse(JSON.stringify(data))
    return FetchHelper.patch(`${window.Api.Customers}/${AuthManager.currentUser.user.id}`, data)
  }

  static updateLists(data){
    data = JSON.parse(JSON.stringify(data))
    data.list_ids = data.lists.map(list => list.id)
    return FetchHelper.put(`${window.Api.Lists}`, data)
  }

  static addPartner(partner){
    let data = JSON.parse(JSON.stringify(partner))
    return FetchHelper.post(window.Api.Partners, data)
  }

  static updatePartner(partner){
    let data = JSON.parse(JSON.stringify(partner))
    return FetchHelper.post(`${window.Api.Partners}/${partner.customer.user.id}`, data)
  }

  static addList(list){
    let data = JSON.parse(JSON.stringify(list))
    return FetchHelper.post(window.Api.Lists, data)
  }

  static updateList(list){
    let data = JSON.parse(JSON.stringify(list))
    return FetchHelper.patch(`${window.Api.Lists}/${list.id}`, data)
  }

  static deleteList(list){
    return FetchHelper.delete(`${window.Api.Lists}/${list.id}`)
  }

  static getLists(type){
    return FetchHelper.get(`${window.Api.Lists}?paginated=false&list_type=${type}`)
  }

  static addLinkie(linkie){
    let data = JSON.parse(JSON.stringify(linkie))
    return FetchHelper.post(window.Api.Linkies, data)
  }

  static updateLinkie(linkie){
    let data = JSON.parse(JSON.stringify(linkie))
    if(data.type){
      data.type = data.type.id
    }
    return FetchHelper.patch(`${window.Api.Linkies}/${linkie.id}`, data)
  }

  static deleteLinkie(linkie, list){
    return FetchHelper.delete(`${window.Api.Linkies}/${linkie.id}?list_id=${list?.id}`)
  }

  static addCustomer(customer){
    let data = JSON.parse(JSON.stringify(customer))
    return FetchHelper.post(window.Api.Customers, data)
  }

  static updateCustomer(customer){
    let data = JSON.parse(JSON.stringify(customer))
    return FetchHelper.patch(`${window.Api.Customers}/${customer.user.id}`, data)
  }

}
